<template>
  <h1 class="display-2 font-weight-light grey--text text--secondary mb-6">
    <slot />
  </h1>
</template>

<script>
  export default {
    name: 'BaseHeading',
  }
</script>
