import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
import VueI18n from 'vue-i18n'
import FlagIcon from 'vue-flag-icon'
import VueScrollTo from 'vue-scrollto'
import IntersectionObserver from 'intersection-observer'

Vue.use(IntersectionObserver)
Vue.use(VueI18n)
Vue.use(FlagIcon)
Vue.use(VueScrollTo)
// Ready translated locale messages
const messages = {
  en: {
      supplierimage: 'suppliersGB.png',
      projectimage: 'projectGB.png',
      interimimage: 'interimGB.png',
    message: {
      img1: 'services_suivi1_gb_tr.png',
      flag: 'us',
      hello: 'hello world',
      ourservices: 'Services',
      ourservices_sub: [
        'Follow-up and Inspections of your suppliers',
        'Project Management',
        'Interim Management',
      ],
      market: 'Markets',
      news: 'News',
      aboutus: 'About Us',
      contact: 'Contact',
      contact_title: 'Contact',
      services_title: 'SERVICES',
      services1_subtitle: 'Suppliers monitoring & Industrial inspections',
      services1_maintext1: 'You are located in North or South America, Asia, Australia (…), a great distance from your European industrial suppliers.<br/><br/>We are based in France, and we can visit your suppliers, locally throughout Europe.<br/><br/>NOELL International specializes in the monitoring and inspection of your European suppliers with whom you have signed industrial supply contracts (design and manufacturing).<br/><br/>Based on our extensive experience in the industry, we quickly detect the risks within the activities of your suppliers. We report potential problems in the project schedule and points of vigilance with respect to expected performance, quality risks and manufacturing processes…<br/><br/>After each Inspection, we send you a detailed report of the activity status of your supplier:<br/><ul><li>Analysis of potential issues to be followed,</li><li>Performance commitments in relation to your specifications,</li><li>Verification of the schedule in relation to contractual planning,</li><li>Quality plan,</li><li>Risk analysis,</li><li>Audit of the preparation for factory acceptance (FAT, FAI…)</li></ul>',
      services1_maintext2: 'The frequency of our inspections depends on your needs and the challenges of your orders to your suppliers.<br/>We can also commit to an inspection plan.',
      services2_subtitle: 'Project Management',
      services2_maintext1: 'You are an industrial firm and you need a Project Management Resource to drive one of your important Projects, or to help you Manage a set of projects.<br/><br/>For example:<br/>… One of your major projects has to start immediately, but you don’t have any Project Manager available at the moment.<br/>… You wish to develop project management capability for your company, but you do not have the available resources, or you do not have the internal know-how to pilot this implementation within your team.<br/>… One of your project managers is temporarily unavailable; or recruiting takes longer than expected, and consequently you are looking for a Senior Project Manager who is immediately operational, for a limited period of time.',
      services2_maintext2: 'We have over 20 years of project management experience in industry and engineering, including in Design, Manufacturing and Industrial projects activities.<br/><br/>We bring our know-how, operational expertise, and rigorous management skills emphasising the importance of team collaboration, as well as a dedicated steering process to deliver the expected results:<br/><ul><li>Budgeted costs and margin,</li><li>Quality level,</li><li>Expected schedule.</ul></li>',
      services2_maintext3: 'Based on our experience in the field, we use robust tools specific to Project management, and a cross-functional approach to the management of people that leads teams to success:<br/><ul><li>Effective inclusion of team and other key stakeholders,</li><li>Continuous management of the project team,</li><li>Compliance matrix (clause by clause) to customer’s specifications,</li><li>Risk analysis and monitoring of related actions plans,</li><li>Costs and margin tracking,</li><li>Cash-flow monitoring,</li><li>Management of planning, tasks and milestones,</li><li>Ongoing task anticipation process, </li><li>Milestone review and reports</li><li>Project dashboard</li><li>Project reporting: Activity, performances, status and progress.</li></ul>',
      services3_subtitle: 'Interim Management',
      services3_maintext1: 'You are a large, medium or small company, or a start-up.<br/><br/>You are looking for a concrete solution to solve an urgent problem. Our Interim Management solution is simpler to set-up than recruitment for a limited period of time, and more effective than using standard interim services.',
      services3_maintext2: 'We get started in your company very quickly, and help you:<br/><ul><li>To have access to urgent solutions,</li><li>To reach your goal(s),</li><li>To have an effective management process,</li><li>To gain an external objective point of view,</li><li>To control your expenses.</li></ul>',
      services3_maintext3: 'Some example scenarios for companies that used Interim Management:<br/><ul><li>Management of crisis situation: an unexpected lack of a manager, Director or key manager who unavailable for a period.</li><li>Management absence: long term unavailability, sickness or maternity/paternity leaves of absence.</li><li>Performance improvement: profitability increases, on-time-delivery improvement, Quality issues…</li></ul>',
      market_title: 'Markets',
      news_title: 'News',
      news_triumf: 'We are proud to announce that we participated in the ARIEL of TRIUMF.',
      news_triumf2: '<u>TRIUMF said</u>: «The testing and results are documented rigorously with photos, video, and other data collection, which is sent to TRIUMF for review. TRIUMF has appointed French consultant <b>NOELL International</b> to attend the testing in-person on our behalf and provide a report.»<br/>',
      news1_maintext1: 'Come and see us at Interpack this May 2020.<br/><br/>Let\'s meet and discuss your needs for your projects and evaluate how we can help you in the effective management and tracking of your French and European Suppliers.<br/>',
      about_title: 'About us',
      about1_subtitle: 'Our story',
      about1_maintext1: ' is a senior Engineer with over 35 years of industry experience.<br/><br/>He has worked across a range of functions including as a Technician, After-Sales Supervisor, Designer… He has developed wide-ranging management expertise working as Project Manager, Projects Director, Operations Director, and General Manager in various industrial and technological companies.<br/><br/>He has worked all his career in an international area:',
      about1_maintext2: '<ul><li>15 years in the packaging sector.</li><li>10 years in the high-speed train sector.</li><li>Extensive experience in both electronics subcontracting and advanced technological solutions within the fields of international research and medicine.</li></ul><br/><br/>He decided in early 2020 to leverage his expertise by creating <strong>NOELL international</strong>.',
      about2_subtitle: 'What we provide',
      about2_maintext1: '<strong>NOELL International</strong> mainly focuses on two activities.<br/><br/>We carry out <strong>industrial Inspections</strong> locally in French industries in order to help International customers to follow-up their french suppliers.<br/>We have learned from our experience that Success in delivery and quality are directly linked to:<br/><ul><li>Regular visits to suppliers,</li><li>Inspection of theirs activities and checks of performances and schedule compliance with the contract and planning,</li><li>Audit of process and risks…</li></ul><br/><br/>We carry out missions of <strong>Project Management</strong> for industrial companies located in France, as well as <strong>Interim Management</strong> on Senior Director positions.',
      about3_subtitle: 'Our commitment',
      about4_subtitle: 'What they say about us',
      about4_rec1_1: 'Mohamed MESSAOUDI',
      about4_rec1_2: 'Senior Commodity Buyer at Alstom',
      about4_rec1_3: 'Oct. 17th 2019, Mohamed was one of Laurent\'s clients',
      about4_rec2_1: 'Jean-Laurent Lagadic',
      about4_rec2_2: 'Electronics R&D Director at Sepro Group',
      about4_rec2_3: 'Oct. 27th 2019, Jean-Laurent and Laurent worked in the same department',
      about4_rec3_1: 'Pierre SAINFORT',
      about4_rec3_2: 'CEO at COMPIN',
      about4_rec3_3: 'Sept. 26th 2011, Pierre was at a higher-level position than Laurent but was not his manager',
      about4_rec4_1: 'Joe Quigley',
      about4_rec4_2: 'Customer Director at Alstom Transportation',
      about4_rec4_3: 'Sept. 22 2011, Joe worked with Laurent in several departments',
      about4_rec5_1: 'Jean-Pierre Guy',
      about4_rec5_2: 'Vice President Business Development & Marketing - MEA Region at Alstom',
      about4_rec5_3: 'Oct. 1st 2011, Jean-Pierre was Laurent\'s manager',
      about4_maintext1: '"Working with Laurent was a rich experience for me as a customer and buyer. We have negotiated different complex contracts that have guided the achievement of the expected objectives and what has made it possible to achieve these objectives and to realize our projects is also his management style, his skills about industrial environment and his ability to make decision.<br/>We have managed challenging situations involving new design projects, design changes in production, industrialization, production\'s ramp up of complex products and also emergencies such interventions on sites. In each situation, Laurent has always managed priorities, constraints as well as the entire organization behind him to bring sustainable solutions with professionalism and in a convivial atmosphere."',
      about4_maintext2: '"I do really appreciate the way Laurent is listening a lot to people and then can bring a clear, global vision to the situation and the strategy.<br/>Then Laurent makes things simple and brings energy and motivation so that everybody goes fast on the action plan and results.<br/>The right adapted organization for successful business, this is how I would summarize Laurent\'s skill."',
      about4_maintext3: '"I know Laurent as Managing Director of one subsidiary of FAIVELEY TRANSPORT in France. He has taken the position 4 years ago to integrate the entity after being acquired. He has been fully able to manage a strong cultural change, implement new organization. He has demonstrated his full ability to drive a team and to deliver and even exceed his commitments.<br/>Laurent is a very hands on person who I could trust and who has in depth control of figures and understanding of business and people skills. Laurent is a self motivated individual, customer oriented."',
      about4_maintext4: '"Laurent was a very detailed Program Manager who was respected both internally and externally. He also kept his commitments to key milestones in his projects which instilled trust with the end customers."',
      about4_maintext5: '"In my role of executive director of the FAIVELEY Transport Electronics Division, Laurent reported to me first as project manager during 7 years. Laurent is hard working, totally dedicated to his mission. He has a great sense of strategy, customer relationships and knows to assess situations and find ways to avoid obstacles in order to achieve the given objectives. He has excellent relationships with people internally and externally and is able to put people at ease when discussing complex issues on projects. He can be very demanding to his team but always with a smile. Laurent can also be strong and pushy but only when needed. Laurent is driven by customer satisfaction while maximising the interest of the company in terms of profit and cash generation. Beside his amazing PM capabilities in a senior position, his engineering background gives him the capabilities to understand technical matters and to ease the search for solutions with the engineering."',
      membrede: 'Member of:',
      about3_maintext1: '<strong>NOELL International</strong> is committed to <strong>Operational Excellence</strong> across all your projects. We adhere to the followings Values:<br/><ul><li>Focused on your requirements,</li><li>Listening and reporting,</li><li>Responsive,</li><li>Efficiency,</li><li>Transparency,</li><li>Rigorous,</li><li>Working with integrity and respect.</li></lu>',
      couveo: 'Bénéficiaire d’un <strong>C</strong>ontrat d’<strong>A</strong>ppui au <strong>P</strong>rojet d’<strong>E</strong>ntreprise, du 8 février 2021 au 7 février 2022, (loi 2003-721 du 1er août 2003 art 20 et 21, et décret 2005-505 du 19 mai 2005), auprès de la Couveuse <strong>COUVEO</strong>',
    },
  },
  fr: {
      supplierimage: 'suppliersFR.png',
      projectimage: 'projectFR.png',
      interimimage: 'interimFR.png',
    message: {
      img1: 'services_suivi1_tr.png',
      flag: 'fr',
      hello: 'coucou',
      ourservices: 'Services',
      ourservices_sub: [
        'Suivi & Inspection de Fournisseurs',
        'Direction et Gestion de Projets',
        'Management de Transition',
      ],
      market: 'Marchés',
      news: 'Actualités',
      aboutus: 'À propos',
      contact: 'Contact',
      contact_title: 'Contact',
      services_title: 'SERVICES',
      services1_subtitle: 'Suivi et Inspection de l’exécution de vos commandes, chez vos Fournisseurs',
      services1_maintext1: 'Vous êtes localisés à l’étranger et vous êtes très éloignés de vos fournisseurs Européens ?<br/><br/>Nous sommes implantés en France, nous vous représentons et intervenons donc localement chez vos fournisseurs dans toute l’Europe, afin de réaliser des Inspections et Audits de suivi de l’exécution de votre commande.<br/><br/>Notre large expérience industrielle nous permet de cibler rapidement les points de vigilance, tout en conservant une vue globale du projet, afin de vous assurer le bon déroulement de votre commande, selon vos exigences contractuelles.<br/><br/>Après chaque visite d’Inspection, nous vous transmettons un rapport détaillé de l’avancement de l’exécution de votre commande par votre fournisseur:<br/><ul><li>Identification argumentée des points de vigilances,</li><li>Respect de la performance attendue en référence à votre cahier des charges,</li><li>Conformité aux engagements de planning, et des délais contractuels,</li><li>Respect des exigences qualité,</li><li>Vérification de la bonne prise en compte d’une analyse des risques,</li><li>Audit de la préparation à la réception et recette finale (FAT, FAI…)</li></ul>',
      services1_maintext2: 'Selon votre besoin et les enjeux de votre commande, nous intervenons soit ponctuellement, soit régulièrement dans le cadre d’un programme de Suivi et d’Inspection sur toute la durée de réalisation de vos projets.',
      services2_subtitle: 'Direction et Gestion de projets, intégré à vos équipes',
      services2_maintext1: 'Vous êtes une PME/PMI/ETI et avez besoin d’une Ressource Opérationnelle pour le Pilotage de l’un de vos projets, ou pour assurer temporairement la Direction de plusieurs Projets.</br></br>… l’un de vos projets important doit impérativement démarrer, mais vous n’avez pas de Chef de projet qui soit immédiatement disponible,<br/>… vous souhaitez mettre en place une Gestion par Projet pour vos activités, mais vous n’avez pas les ressources disponibles, ou bien les compétences en interne nécessaires à ce Pilotage sont insuffisantes,<br/>… l’un de vos chefs de projet est absent, ou bien votre processus de recrutement n’est pas encore finalisé: vous recherchez donc un chef de projet très expérimenté qui soit immédiatement Opérationnel, pour une mission temporaire.',
      services2_maintext2: 'Nous avons plus de 20 années d’expérience de Gestion et Direction de projets dans l’industrie  et l’ingénierie (projets d’études, de fabrication, projets industriels). <br/>Nous vous apportons notre savoir-faire, notre maitrise opérationnelle, un management exigeant et respectueux des équipes, un pilotage spécifique afin d’atteindre les Performances attendues:<br/><ul><li>Respect des Coûts budget,</li><li>Obtention du niveau de Qualité attendu,</li><li>Tenue des plannings et des engagements de Délai.</ul>',
      services2_maintext3: 'Forts de notre expérience terrain, nous nous appuyons sur des outils de gestion par projet qui sont éprouvés et sur une qualité de management transversal:<ul><li>Inclusion des équipes et des parties prenantes,</li><li>Animation récurrente des équipes projets,</li><li>Matrice de conformité pour la prise en compte des exigences clients,</li><li> Pilotage des analyses de risques et suivi des plans d’actions,</li><li>Suivi des coûts et de la marge,</li><li>Pilotage du CashFlow,</li><li>Gestion du Planning PERT, avec activités et Jalons,</li><li>Pilotage par Anticipation des activités,</li><li>Pilotage des revues de jalons, et compte-rendus,</li><li>Tenue du Tableau de bord du projet,</li><li>Reporting des activités, des performances et de l’avancement du projet</li></ul>',
      services3_subtitle: 'Management de Transition',
      services3_maintext1: 'Vous êtes un Grande Entreprise, un ETI, une PME, une PME ou une start-up.<br/><br/>Vous recherchez une solution concrète vous permettant de répondre efficacement à une solution d’Urgence. Le Management de Transition est plus simple à mettre en place qu’un recrutement à durée déterminée, et plus efficace que le recours à l’emploi d’un Cadre Supérieur via une agence d’intérim.',
      services3_maintext2: 'Nous intervenons très rapidement, et vous permettons ;<br/><ul><li>d’accéder en urgence à des solutions concrètes,</li><li>d’avoir une garantie de réussite,</li><li>d’assurer une gestion de projet temporaire avec efficacité,</li><li>de vous appuyer sur une objectivité extérieure,</li><li>de maîtriser les coûts.</li></ul>',
      services3_maintext3: 'Exemples de situations d’entreprises qui font appel au Management de Transition:<br/><ul><li>Management en situation de crise: absence inopinée d’un dirigeant, vacance prolongé d’un cadre clef..</li><li>Management relais: absence longue durée, maladie, congé parental…</li><li>Amélioration de la performance: augmentation de la rentabilité, respect des délais de livraison, amélioration du niveau de qualité…</li></lu>',
      market_title: 'Marchés',
      news_title: 'Actualités',
      news_triumf: 'We are proud to announce that we participated in the ARIEL of TRIUMF.',
      news_triumf2: '<u>TRIUMF said</u>: «The testing and results are documented rigorously with photos, video, and other data collection, which is sent to TRIUMF for review. TRIUMF has appointed French consultant <b>NOELL International</b> to attend the testing in-person on our behalf and provide a report.»<br/>',
      news1_maintext1: 'Venez nous rencontrer au salon Interpack 2020.<br/><br/>Parlons de vos projets de Lignes de Conditionnement, et de vos besoins.<br/>Evaluons ensemble comment nous pouvons vous aider dans le suivi de vos fournisseurs qui sont localisés en France et en Europe.<br/>',
      about_title: 'À propos',
      about1_subtitle: 'Notre histoire',
      about1_maintext1: ' est Ingénieur, riche de plus de 35 années d’expérience dans l’Industrie.<br/><br/>Il a débuté sa carrière par de multiples postes dans des PMI en tant que Technicien, Service après-vente, Méthodiste, Designer… progressant continuellement en améliorant ses connaissances.<br/>Il a ensuite travaillé en Gestion de projets, Direction de projets, Direction des opérations et Direction Générale, dans plusieurs entreprises Industrielles et de hautes-technologies.<br/>Toute sa carrière été marquée par le business International.',
      about1_maintext2: 'Après 15 années d’activités dans le marché du Packaging, il a travaillé 10 années auprès des grands comptes du secteur Ferroviaire et de la grande vitesse, puis dans la sous-traitance Electronique, et enfin pour des applications Médicales et les grands Centres de Recherches internationaux.<br/><br/>Début 2020, il a décidé de créer la structure NOELL International, afin de mettre sa compétence et la richesse de son expérience à la disposition de tous ses clients.',
      about2_subtitle: 'Nos services',
      about2_maintext1: '<strong>NOELL international</strong> apporte deux activités principales à tous ses clients industriels, PME et PMI.<br/><br/>Nous réalisons des missions de Gestion de projets, pour nos clients industriels en France.<br/>Et nous intervenons sur des fonctions de <strong>Direction Opérationnelle</strong> ou de Direction Générale, pour des missions de <strong>Management de Transition</strong>.<br/><br/>Nous apportons également notre savoir-faire industriel à nos clients du Grand export (Usa, Asie, Australie…), en réalisant des <strong>suivis et inspections chez leurs fournisseurs</strong> qui sont localisés en France et en Europe.<br/>Nous savons par expérience que le Succès des commandes et projets en terme de Délais et Performances, est très directement lié:<br/>-à un suivi régulier de ses fournisseurs, en se déplaçant sur leur site,<br/>-à l’Inspection de leurs activités en auditant l’avancement du planning d’exécution et l’atteinte des performances contractuelles,<br/>-en inspectant les processus, les procédés industriels et la gestion des risques…',
      about3_subtitle: 'Notre engagement',
      about4_subtitle: 'What they say about us',
      about4_rec1_1: 'Mohamed MESSAOUDI',
      about4_rec1_2: 'Senior Commodity Buyer at Alstom',
      about4_rec1_3: '17 octobre 2019, Mohamed était un client de Laurent',
      about4_rec2_1: 'Jean-Laurent Lagadic',
      about4_rec2_2: 'Electronics R&D Director at Sepro Group',
      about4_rec2_3: '27 octobre 2019, Laurent a travaillé avec Jean-Laurent dans le méme service',
      about4_rec3_1: 'Pierre SAINFORT',
      about4_rec3_2: 'CEO at COMPIN',
      about4_rec3_3: '26 septembre 2011, Pierre occupait une fonction plus élevée que Laurent mais n’était pas son responsable',
      about4_rec4_1: 'Joe Quigley',
      about4_rec4_2: 'Customer Director at Alstom Transportation',
      about4_rec4_3: '22 septembre 2011, Joe a travaillé avec Laurent dans des services différents',
      about4_rec5_1: 'Jean-Pierre Guy',
      about4_rec5_2: 'Vice President Business Development & Marketing - MEA Region at Alstom',
      about4_rec5_3: '1 octobre 2011, Jean-Pierre était le responsable de Laurent',
      about4_maintext1: '"Working with Laurent was a rich experience for me as a customer and buyer. We have negotiated different complex contracts that have guided the achievement of the expected objectives and what has made it possible to achieve these objectives and to realize our projects is also his management style, his skills about industrial environment and his ability to make decision.<br/>We have managed challenging situations involving new design projects, design changes in production, industrialization, production\'s ramp up of complex products and also emergencies such interventions on sites. In each situation, Laurent has always managed priorities, constraints as well as the entire organization behind him to bring sustainable solutions with professionalism and in a convivial atmosphere."',
      about4_maintext2: '"I do really appreciate the way Laurent is listening a lot to people and then can bring a clear, global vision to the situation and the strategy.<br/>Then Laurent makes things simple and brings energy and motivation so that everybody goes fast on the action plan and results.<br/>The right adapted organization for successful business, this is how I would summarize Laurent\'s skill."',
      about4_maintext3: '"I know Laurent as Managing Director of one subsidiary of FAIVELEY TRANSPORT in France. He has taken the position 4 years ago to integrate the entity after being acquired. He has been fully able to manage a strong cultural change, implement new organization. He has demonstrated his full ability to drive a team and to deliver and even exceed his commitments.<br/>Laurent is a very hands on person who I could trust and who has in depth control of figures and understanding of business and people skills. Laurent is a self motivated individual, customer oriented."',
      about4_maintext4: '"Laurent was a very detailed Program Manager who was respected both internally and externally. He also kept his commitments to key milestones in his projects which instilled trust with the end customers."',
      about4_maintext5: '"In my role of executive director of the FAIVELEY Transport Electronics Division, Laurent reported to me first as project manager during 7 years. Laurent is hard working, totally dedicated to his mission. He has a great sense of strategy, customer relationships and knows to assess situations and find ways to avoid obstacles in order to achieve the given objectives. He has excellent relationships with people internally and externally and is able to put people at ease when discussing complex issues on projects. He can be very demanding to his team but always with a smile. Laurent can also be strong and pushy but only when needed. Laurent is driven by customer satisfaction while maximising the interest of the company in terms of profit and cash generation. Beside his amazing PM capabilities in a senior position, his engineering background gives him the capabilities to understand technical matters and to ease the search for solutions with the engineering."',
      membrede: 'Membre de:',
      about3_maintext1: '<strong>NOELL international</strong> s’engage à vous apporter toute son expertise industrielle et s’appuie sur les valeurs suivantes:<br/><ul><li>Prise en compte de votre besoin,</li><li>Ecoute et communication,</li><li>Réactivité,</li><li>Efficacité et rigueur,</li><li>Confiance,</li><li>Exigence,</li><li>Respect de l’homme au sein des organisations.</li></ul>',
      couveo: 'Bénéficiaire d’un <strong>C</strong>ontrat d’<strong>A</strong>ppui au <strong>P</strong>rojet d’<strong>E</strong>ntreprise, du 8 février 2021 au 7 février 2022, (loi 2003-721 du 1er août 2003 art 20 et 21, et décret 2005-505 du 19 mai 2005), auprès de la Couveuse <strong>COUVEO</strong>',
    },
  },
}
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
